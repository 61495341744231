.account-created {
    padding: 20px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
        width: 150px;
    }
    h1 {
        margin-top: 20px;
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 600;
    }
    h2 {
        font-size: 14px;
        font-weight: 300;
        margin-top: 4px;
        margin-bottom: 32px;
    }
}