.create-address {
  padding: 20px;
  p {
    span {
      font-weight: 700;
    }
  }
}
.refine-location {
  padding: 20px;
  align-items: "center";
  align-self: "center";
  #mapbox-container {
    position: relative;
    width: "100%";
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .marker {
      width: 60px;
      padding-bottom: 60px;
      position: absolute;
    }
    .mapboxgl-control-container {
      display: none;
    }
    .search {
      position: absolute;
      top: 20px;
      input {
        width: 40vw;
        padding-left: 30px;
      }
      img {
        position: absolute;
        top: 8px;
        left: 6px;
        width: 20px;
        height: 20px;
      }
    }
    .locate {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: none;
      border: none;
      box-shadow: none;
      z-index: 99;
      :active {
        transform: scale(0.98);
        /* Scaling button to 0.98 to its original size */
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        /* Lowering the shadow */
      }
      img {
        width: 80px;
        height: 80px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .message {
        background-color: #304050;
        color: white; 
        padding: 8px;
        margin-bottom: 8px;
        border-radius: 12px;
        font-size: 14px;
      }
    }
  }
  .bottom-container {
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
}

.address-submited {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  .success-icon {
    width: 60px;
    height: 60px;
    margin: 10px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: #304050;
  }
  .content {
    margin-top: 20px;
    border-radius: 16px;
    background-color: #fafafa;
    border-width: 1px;
    border-style: solid;
    border-color: #eff0f0;
    padding: 20px;
    .section {
      flex-direction: row;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      align-items: center;
      img {
        width: 45px;
        margin-right: 12px;
      }
      span {
        font-size: 13px;
        color: #304050;
        text-align: left;
        margin: 0px;
        padding: 0px;
        font-weight: 500;
      }
    }
  }

  .bottom-container {
    width: 80%;
    margin-top: 20px;
  }
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px;
  h1 {
    font-size: 32px;
  }
}

.image-container {
  width: 200px;
  height: 200px;
  background-color: #fce9e9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}

.error-icon {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.warning-container {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #f5d9bc;
  margin-top: 10px;
  color: #663300;
}

.instruction-container {
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin-top: 10px;
    color: #304050;
  }

  .big-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    margin-bottom: 8px;
    flex: 1;
  }
  
  .checkbox-label {
    flex-grow: 1;
  }
  
  .big-checkbox .form-check-input {
    width: 1.1rem;
    height: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  
  .big-checkbox .form-check-input:checked {
    background-color: #40AABF; /* Change this to your desired color for the checked state */
    border-color: #40AABF;
    position: relative;
  }
  
  .big-checkbox .form-check-input:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    background-color: white;
    border-radius: 50%;
  }

  .street-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #AEB3B7;
  }
  
  .street-item.selected {
    background-color: #E9F9FC;
  }

  .add-icon {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  .custom-input {
    margin-top: 12px;
    border: none;
    border-bottom: 2px solid #ccc;
    border-radius: 0;
  }
  
  .custom-input:focus {
    border-bottom: 2px solid #304050;
    box-shadow: none;
  }