.verify-account {
    padding: 20px;
    .resend-link {
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration-line: underline;
        color: #047D95;
    }
    .bottom-container {
        position: absolute;
        bottom: 10px;
        left:0px;
        padding: 10px;
        width: 100%;
        border-top: 1px solid #636F701A;
        p {
            margin: 0px;
            padding: 0px;
            font-size: 12px;
            text-align: center;
        }
        a {
            margin: 0px;
            text-align: center;
            text-decoration: underline;
            color: #047D95;
            margin-bottom: 10px;
        }
    }
}