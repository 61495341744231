.create-account {
    padding: 20px;
    min-height: 95vh;
    .bottom-container {
        position: absolute;
        bottom: 10px;
        left:0px;
        padding: 10px;
        width: 100%;
        border-top: 1px solid #636F701A;
        p {
            margin: 0px;
            padding: 0px;
            font-size: 12px;
            text-align: center;
        }
        a {
            margin: 0px;
            text-align: center;
            text-decoration: underline;
            color: #047D95;
            margin-bottom: 10px;
        }
    }
}